var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-toolbar',{staticStyle:{"margin-bottom":"1em"}},[_c('v-toolbar-title',[_vm._v("Technology Services Dashboard")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"6","lg":"4"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"padding-bottom":"0"}},[_c('v-text-field',{attrs:{"label":"Filter Links","hint":"Note: Filter searches both labels and links","persistent-hint":"","outlined":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_vm._l((_vm.filteredLinks),function(ref,index){
var text = ref.text;
var links = ref.links;
return _c('v-list',{key:'list-' + index},[_c('v-subheader',{style:(_vm.dark ? 'background-color:#333' : 'background-color:#eee')},[_vm._v(_vm._s(text))]),_vm._l((links),function(ref,linkIndex){
var text = ref.text;
var link = ref.link;
var internal = ref.internal;
return _c('v-list-item',{key:'link-' + index + '-' + linkIndex,attrs:{"to":internal ? link : null,"href":internal ? null : link,"target":internal ? '' : '_blank'}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)})],2)})],2)],1),_c('v-col',{attrs:{"cols":12,"md":"6","lg":"4"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Department Employees")])],1),_c('v-list',_vm._l((_vm.employees),function(ref,index){
var name = ref.name;
var ext = ref.ext;
var office = ref.office;
var cell = ref.cell;
return _c('v-list-item',{key:'emp-' + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(office))])],1),_c('v-list-item-action',[_c('v-list-item-subtitle',[_vm._v("Extension: "+_vm._s(ext))]),(cell)?_c('v-list-item-subtitle',[_vm._v("Cell: "+_vm._s(cell))]):_vm._e()],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":12,"lg":"4"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("GreatScots Search")])],1),_c('home-directory-search')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }